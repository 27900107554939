const rgb2hex = (r, g, b) => '#' + [r, g, b].map(x => Math.round(x * 220).toString(16).padStart(2, 0)).join('')

export default {
  getRandColor () {
    return this.rainbowStop(Math.random())
  },
  rainbowStop (h) {
    const f = (n, k = (n + h * 12) % 12) => 0.5 - 0.5 * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return (rgb2hex(f(0), f(8), f(4)))
  },
  bwStop (h) {
    return rgb2hex(h, h, h)
  },
  prettifyDate (date) {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
    return (new Date(date)).toLocaleDateString('en-US', options)
  },
  prettifyDate2 (date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return (new Date(date)).toLocaleDateString('en-US', options)
  },
  async loadAndProcessPostsData (context, baseDir, arrSlice = null) {
    let posts = await context.keys().map(key => ({
      ...context(key),
      path: `/${baseDir}/${key.replace('.md', '').replace('./', '')}/`
    }))

    // Object error checking
    for (let i = 0; i < posts.length; i++) {
      if (!posts[i].attributes.post_title) {
        // posts[i].attributes.post_title = posts[i].path.substr(posts[i].path.lastIndexOf('/') + 1, posts[i].path.length)
      }
      if (posts[i].attributes.date) {
        posts[i].attributes.date = new Date(posts[i].attributes.date)
      } else {
        posts[i].attributes.date = new Date(0)
      }
      posts[i].color = this.getRandColor()
    }

    // Filter out any posts that are unlisted
    posts = posts.filter(post => !post.attributes.unlisted_post)
    // Sort by date, most recent first
    posts.sort((a, b) => (a.attributes.date > b.attributes.date ? 1 : -1))

    if (arrSlice != null) {
      return posts.slice(arrSlice)
    } else {
      return posts
    }
  },
  findPrevAndNextPost (allPosts, currPost) {
    let prev = null
    let next = null

    const currArticleIdx = allPosts.findIndex(postObj => postObj.path === currPost.path)

    let searchIdx = currArticleIdx
    while (prev == null) {
      ++searchIdx
      if (currArticleIdx < allPosts.length - 1) {
        const localArticle = allPosts[searchIdx]
        if (!localArticle.unlisted_post) {
          prev = localArticle
        }
      } else {
        break
      }
    }
    searchIdx = currArticleIdx
    while (next == null) {
      --searchIdx
      if (currArticleIdx > 0) {
        const localArticle = allPosts[searchIdx]
        if (!localArticle.unlisted_post) {
          next = localArticle
        }
      } else {
        break
      }
    }
    return [prev, next]
  },
  async defaultPostsContentFunction ($content, params, dir) {
    const article = await $content(dir, params.post).fetch()
    const content = article

    if (!content.id) {
      content.id = `${this.domain}${content.path}`
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    if (content.date) {
      content.date = new Date(content.date)
    } else {
      content.date = new Date(0)
    }

    if (content.project_start) {
      content.project_start = new Date(content.project_start)
      content.project_start.setHours(0, 0, 0, 0)
    }

    if (!content.project_end) {
      content.project_end = today
    } else {
      content.project_end = new Date(content.project_end)
      content.project_end.setHours(0, 0, 0, 0)
    }

    const allPosts = await $content('posts', { deep: true })
      .only(['post_title', 'dir', 'slug', 'date', 'unlisted_post', 'hero', 'path'])
      .sortBy('date', 'desc')
      .fetch()

    const postsPrevNext = this.findPrevAndNextPost(allPosts, content)

    return {
      content,
      postsPrevNext
    }
  },
  async searchArticlesByQuery (query, $content, params, dir, allPosts) {
    if (!query || !query.length > 1 || !query.trim().length > 1) {
      return allPosts
    }
    query = query.trim().toLowerCase()
    let searchedArticles = await $content(dir, { deep: true })
      .only(params)
      .fetch()
    searchedArticles = searchedArticles.filter((post) => {
      if (post.unlisted_post || !post.post_title) {
        return false
      }

      if (post.post_title.toLowerCase().includes(query)) {
        return true
      } else if (post.project_title && post.project_title.toLowerCase().includes(query)) {
        return true
      }

      if (post.project_desc && post.project_desc.toLowerCase().includes(query)) {
        return true
      } else if (post.excerpt_desc && post.excerpt_desc.toLowerCase().includes(query)) {
        return true
      }

      return false
    })
    // console.log(searchedArticles)
    if (allPosts && allPosts.length) {
      const searchedTitleSet = new Set()
      for (const article of searchedArticles) {
        searchedTitleSet.add(article.post_title)
      }
      return allPosts.filter(post => searchedTitleSet.has(post.post_title))
    } else {
      console.log('allposts empty')
      return searchedArticles
    }
  },
  domain: 'https://austindibble.com'
}
