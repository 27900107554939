import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0a7e2484&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0a7e2484&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7e2484",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavButton: require('/builds/dibz15/dibz15.gitlab.io/components/NavButton.vue').default,ExpandingSearch: require('/builds/dibz15/dibz15.gitlab.io/components/ExpandingSearch.vue').default,NavItem: require('/builds/dibz15/dibz15.gitlab.io/components/NavItem.vue').default})
