const resume = '/misc/PhD_resume_06_24.pdf'
const cv = '/misc/PhD_CV_06_24.pdf'

export default {
  socials: [
    { external: true, url: 'https://www.linkedin.com/in/austin-dibble', icon: 'mdi-linkedin', color: '#2867b2', text: 'LinkedIn' },
    { external: true, url: 'https://github.com/Dibz15', icon: 'mdi-github', color: '#6a737d', text: 'GitHub' },
    { external: true, url: 'https://gitlab.com/dibz15', icon: 'mdi-gitlab', color: '#3f3177', text: 'Gitlab' },
    { emailName: 'austin.dibble15', emailDomain: 'gmail', emailTld: 'com' }
  ],
  resume,
  cv,
  resume_docs: [
    {
      text: 'Resume',
      icon: 'mdi-card-account-details',
      clickFunction: function readFile () {
        window.open(resume, '_blank') // to open in new tab
      }
    },
    {
      text: 'CV',
      icon: 'mdi-file-account',
      clickFunction: function readFile () {
        window.open(cv, '_blank') // to open in new tab
      }
    }
  ],
  description: 'A lifelong learner, engineer, and developer. I pride myself in hard work and an interest in everything.'
}
