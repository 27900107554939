

export default {
  props: {
    nav: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fab: false,
      active: false
    }
  }
}
