

import NavButton from '~/components/NavButton.vue'
import NavItem from '~/components/NavItem.vue'
import Social from '~/assets/social.js'
import Utils from '~/assets/utils.js'
import ExpandingSearch from '~/components/ExpandingSearch.vue'

export default {
  components: {
    NavButton,
    NavItem,
    ExpandingSearch
  },
  data () {
    const navList = [
      {
        text: 'My Work',
        subnav: [
          { text: 'Projects', url: '/posts/projects/', icon: 'mdi-wrench' },
          { text: 'Posts', url: '/posts/', icon: 'mdi-post-outline' }
        ]
      },
      {
        text: 'Resume/CV',
        subnav: Social.resume_docs
      },
      { text: 'About Me', url: '/about/', icon: '' },
      {
        text: 'Contact', dial: true, subnav: Social.socials
      }
    ]

    for (const item of navList) {
      if (!(item.clickFunction)) {
        item.clickFunction = function () {}
      }
      if (item.subnav) {
        for (const subnavItem of item.subnav) {
          if (!(subnavItem.clickFunction)) {
            subnavItem.clickFunction = function () {}
          }
        }
      }
    }

    return {
      navList,
      drawer: false,
      group: null,
      navImage: true
    }
  },
  head () {
    const canonicalUrl = Utils.domain + this.$route.path
    return {
      link: [
        { rel: 'canonical', href: canonicalUrl },
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Caveat&display=swap' }
      ],
      meta: [{ property: 'og:url', content: canonicalUrl }]
    }
  },
  computed: {
    currTheme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    currBackground () {
      return this.$vuetify.theme.themes[this.currTheme].background
    },
    dense () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
    $route () {
      this.$nuxt.refresh()
    }
  }
}
