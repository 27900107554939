

export default {
  props: {
    nav: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      fab: false
    }
  }
}
