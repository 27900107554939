
import Utils from '~/assets/utils'

export default {
  data () {
    return {
      searchClosed: true,
      searchedArticles: [],
      searchQuery: ''
    }
  },
  watch: {
    async searchQuery (query) {
      this.searchedArticles = await Utils.searchArticlesByQuery(query, this.$content, ['post_title', 'project_title', 'project_desc', 'excerpt_desc', 'hero', 'dir', 'slug'], 'posts', [])
      // console.log(this.searchedArticles)
    }
  }
}
